import logo from './sentablogo.png';
import './App.css';
import home1 from './img/main.jpg'
import home2 from './img/main2.jpg'
import home3 from './img/main3.jpg'
import home4 from './img/main4.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import CollapsibleExample from './Components/navbar-sentab';
function App() {
  return (
    <div className="App">
      <div id="fb-root"></div>
      <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0" nonce="NpK7drT6"></script>
      <CollapsibleExample/>
      <header className="App-header">
        {/* Home wallpaper */}
        <div class="banner">
          <img src={home1} width="100%" alt='...' />
          <h2 class="heading">WELCOME TO SENTAB </h2>
        </div>

        <section id='about' class="section top-section bg-white text-dark">
          <div class="content-container content-theme-dark">
            <div class="content-inner">
              <div class="content-center">
                <h1>ABOUT US</h1>
                &nbsp;
                <p class="subtopic">We are Sentab International.</p>
                <p class="bodytext bodytextstyle"> At Sentab, our expertise lies in providing consultation services to Sri
                  Lankan people, to help them achieve their foreign employment dream.
                  We help skilled workers of our country to gain job opportunities from European to Middle Eastern countries.
                  We are dedicated to providing our clients with the best opportunities and helping them with their foreign employment and
                  migration needs.
                  Sentab also provides world class welding courses for Sri Lankans to become skilled in the art of welding.
                  We believe in and prepare our clients to achieve their goals with professionalism and
                  precision.</p>
              </div>
            </div>
          </div>
        </section>
        <div class="banner">
          <img src={home2} width="100%" alt='...' />
          {/* <h2 class="heading">WELCOME TO SENTAB </h2> */}
        </div>
        <section id='about' class="section top-section bg-white text-dark">
          <div class="content-container content-theme-dark">
            <div class="content-inner">
              <div class="content-center">
                <h1>JOBS</h1>
                &nbsp;
                <p class="subtopic">FIND YOUR PERFECT MATCH! - SENTAB INTERNATIONAL – YOUR VISIONARY CONSULTING AGENCY</p>
                <p class="bodytext bodytextstyle"> Let Sentab International guide you in finding the perfect foreign employment that suits your skill set and your passion.There are many fields in which you can find job opportunities abroad. From the hospitality industry to the IT sector and everything in between, businesses are on the lookout for recruits. Sentab can help you to stay ahead of the competition and impart to you the knowledge, expertise and experience to make your foreign employment and migration dreams come true. So, let’s go hunting for your perfect job and career path!</p>
              </div>
            </div>
          </div>
        </section>
        <div class="banner">
          <img src={home3} width="100%" alt='...' />
          {/* <h2 class="heading">WELCOME TO SENTAB </h2> */}
        </div>
        <section id='about' class="section top-section bg-white text-dark">
          <div class="content-container content-theme-dark">
            <div class="content-inner">
              <div class="content-center">
                <h1>NEWS AND BLOGS</h1>
                &nbsp;




              </div>
            </div>
          </div>
        </section>
        <div class="banner">
          <img src={home4} width="100%" alt='...' />
          {/* <h2 class="heading">WELCOME TO SENTAB </h2> */}
        </div>


      </header>

      <section id='about' class="section top-section bg-white text-dark">
        <div class="content-container content-theme-dark">
          <div class="content-inner">
            <div class="content-center">
              <h1>CONTACT US</h1>
              &nbsp;

      
                <div class="row">
                  <div class="col">
                    <div className="google-map">
                      <iframe
                        title="map" width="70%" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.645204359012!2d79.92614999999999!3d6.8129374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae24ffdc7afe7cb%3A0x1c2ba511a06de085!2s326%20Sirimangala%20Watta%20Road%2C%20Piliyandala!5e0!3m2!1ssi!2slk!4v1674023560843!5m2!1ssi!2slk"
                      ></iframe>
                    </div>
                  </div>
                  <div class="col">
                
          <img src={logo} width="10%" alt='...' className="d-flex flex-row"/>

          <p className="d-flex flex-row">CHANGE YOUR LIFE WITH SENTAB</p>

          <p className="d-flex flex-row">Have a chat with us +94 11 2 708 290
          Mail your CV to cv@sentab.lk and start your path towards achieving your dream. </p>
          <br></br>
          <p className="d-flex flex-row">326/2, Srimangala Watte, Piliyandala, Sri Lanka</p>


                  </div>
                </div>



            </div>
          </div>
        </div>
      </section>





{/* Bottom nav bar */}
      <nav class="navbar navbar-expand-lg navbar-light bg-light">

        <div class="container-fluid">

          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarCenteredExample"
            aria-controls="navbarCenteredExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>


          <div
            class="collapse navbar-collapse justify-content-center" id="navbarCenteredExample">
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  
                  data-mdb-toggle="dropdown"
                  aria-expanded="false">
                  Dropdown
                </a>

                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>



    </div>
  );
}

export default App;
